.uk-navbar-container {
  padding: 18px 0px;
}

.fa.fa-custom-logo-white::before {
  content: "";
  display: block;
  background: url("/../../src/assets/images/icon/logo.png?v=1");
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  color: white;
}
