.my-training {
  position: relative;
  flex: 1;
}
.list ::-webkit-scrollbar {
  display: none !important;
}

.assigned {
  font-size: 20px !important;
  height: 120px;
  background-color: #eaf3fb !important;
  background-image: linear-gradient(to right, #f8c46c, #fbaf7b) !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.assigned img {
  height: 70px;
}
.welcome {
  font-size: 20px;
  height: 70px;
  background-color: #eaf3fb;
  background-image: linear-gradient(to right, #aed8fd, #e9f4fe);
  color: rgba(0, 0, 0, 0.85);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}

.ant-card-body {
  padding: 19px !important;
}
