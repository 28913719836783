.ant-btn-success { 
    background-color: #2680e8 !important;
    color: #fff !important;
    border: 1px solid transparent;
    font-weight: 700 !important;
  }
  
  /* Hover + Focus */
  .ant-btn-success:active,
  .ant-btn-success:hover,
  .ant-btn-success:focus {
      font-weight: 700 !important;
    background-color: #1f68bb !important;
    color: #fff;
  }
.trial_form {
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.trial_form input.ant-input {
    height: 50px;
    padding-left: 35px !important;
    padding-right: 12px;
}

.trial_form .ant-form-item-label label {
    color: #333 !important;
    font-size: 0.875rem !important;
    margin-top: 13px;
    margin-bottom: 8px;
    font-weight: bolder;

}

.trial_form .ant-row.ant-form-item {
    display: list-item !important;

}

/* .trial_form .ant-input-prefix {
    position: absolute;
    top: 40% !important;
}

.trial_form .btn-wrapper {
    align-self: flex-start;
    margin-top: 40px !important;
    padding-left: 0px !important;
} */


.trial_form .has-error .ant-form-explain {
    margin-bottom: 10px !important
}