.rtl-text-right{
  direction: rtl;
  text-align: right;
}
.rtl-text{
  direction: rtl; 
}


.ant-col h1 {
  font-size: 1.25rem !important;
}
 
.ant-table-column-title,
.ant-radio-button-wrapper span,
.gx-text-uppercase,
.gx-chart-title,
.ant-card-head-title {
  font-weight: 900 !important;
}
.anticon:before {
  display: block !important;
  font-family: "anticon" !important;
}

#instructor img {
  border: 1px solid #e8e8e8;
}
#stats li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
}
